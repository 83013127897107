.container{
    width: 900px;
    height: 1100px;
}

.invoice-head{
margin: auto;
text-align: center;
}
.invoice-head>h3{
    margin: auto;
    }
@media print {
    html, body {
        background: #FFF;

    }

    .container{
        width: 900px;
        height: 1100px;
    }

    @page {
        /*size: A4;*/
        /*size: landscape;*/
        size: A4 portrait;
        margin: 0;
    }
}