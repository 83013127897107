.logo-area {
    overflow: hidden;
    padding: 15px;
    text-decoration: none;
}

.logo-area:hover {
    text-decoration: none;
}

.typo-area {
    margin: 3px 20px;
    overflow: hidden;
    display: flex;
}

.logo-image {
    width: 40px;
    float: left;
}

.logo-typo {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    float: left;
    line-height: 40px;
    color: #555555;
    margin-left: 15px;
}

.logo-slogan {
    color: #607d8b;
    padding: 5px 0;
    font-size: 12px;
    font-family: "Roboto", arial;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    margin-top: 15px;
    background: #8062580f;
    border-radius: 30px;
}