a {
    color: #405c69;
    text-decoration: none;
}

a:hover {
    color: rgb(155, 31, 31);
    text-decoration: none;
}

main {
    max-width: 100%;
}

.MenuLabel0 a {
    color: #222222;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.MenuLabel0 a {
    text-decoration: none;
}

.MenuLabel0 .MuiListItemIcon-root {
    min-width: 48px;
}

.MenuLabel0 .MuiSvgIcon-root {
    font-size: 20px;
}

.MenuLabel0 .MuiTypography-root {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
}

.MenuLabel1 .MuiListItemIcon-root {
    /* min-width: 36px; */
    margin-left: 16px;
}


/* Login Page */

.login-logo>img {
    width: 150px;
}

.login-area {
    border: 1px solid #c0c0c0;
    padding: 30px;
    border-radius: 15px;
}


/*Product Section*/

.tableTr>th {
    border-bottom: none !important;
    font-weight: bold;
    font-size: 0.875rem;
    background: rgba(145, 158, 171, 0.08);
    font-family: "Be Vietnam", sans-serif;
    color: rgb(99, 115, 129);
}

.tableTr>td {
    border-bottom: none !important;
    font-size: 0.875rem;
    font-family: "Be Vietnam", sans-serif;
    font-weight: 600;
    line-height: 1.57143;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.5s ease;
}

.productMobile-card-col {
    padding-right: 3px;
    padding-left: 3px;
    padding-bottom: 10px;
}

.product-card-header {
    padding: 10px !important;
    font-size: 15px !important;
}

.product-card-image {
    cursor: pointer;
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 0;
    margin: auto;
    height: 250px;
}
.product-category{

}
.product-category>ul{
    
}
.product-category-btn{
    background: none;
    border: none;
    height: 40px;
}
/*Breadcrumbs*/

.MuiBreadcrumbs-ol {
    margin-bottom: 25px !important;
}


/*Page Common*/

.page-common-title {
    font-weight: bold;
    font-size: 22px;
    height: 40px;
}
.page-common-sub-title{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.page-common-breadcrumbs{
    width: 50%;
}

/*Order Section*/


.order-card {
    overflow: hidden;
    box-shadow: 0 0 6px 3px #99999940;
    border: 0;
    transition: all 0.5s ease;
    margin-bottom: 1.5rem!important;
}

.order-card:hover {
    box-shadow: 0 0 6px 6px #77777760;
}
.order-card-image {
    cursor: pointer;
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
    margin: auto;
}
.order-paid-btn {
    color: #fe4217;
    background-color: rgba(214, 76, 44, 0.16);
    cursor: pointer;
    width: 70px;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    padding: 2px 9px 3px 8px;
}
.user-orders-number {
    font-size: 12px;
}

.user-orders-time {
    font-size: 10px;
}

.user-Orders-manage {
    font-size: 12px;
    float: right;
    vertical-align: top;
}

.user-orders-img {
    float: left;
}

.user-orders-img>img {
    width: 80px;
    height: 80px;
}
.variant-list {
    display: inline;
    list-style: none;
    margin-left: -43px;

  }
  .variant-list li {
    display: inline;
    list-style: none;
    padding: 5px;
  }
  
  .variant-list li:after {
    content: ",";
  }
  .variant-list li:last-child:after {
    content: "";
}
.shop-overview-header{
    border-bottom: none!important;
    display: inline-grid;
    grid-template-columns: 25% 25% 25% 25%;
    background-color: #f1f2f3;
    border-radius: 50px;
    width: 70%;
    margin: auto!important;
}
.shop-overview-header>a{
    text-align: center;
}
.shop-overview-header>.active{
    border: none!important;
    background: #fff;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
    color: red !important;
    border-radius: 50px;
}

.notification-overview-header{
    border-bottom: none!important;
    display: inline-grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    background-color: #f1f2f3;
    border-radius: 50px;
    width: 80%;
    margin: auto!important;
}
.notification-overview-header>a{
    text-align: center;
}
.notification-overview-header>.active{
    border: none!important;
    background: #fff;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
    color: red !important;
    border-radius: 50px;
}

.product-list-tab{
    border-bottom: none!important;
    display: inline-grid;
    grid-template-columns: 50% 50%;
    background-color: #f1f2f3;
    border-radius: 50px;
    width: 50%;
    margin: 10px;
}
.product-list-tab>a{
    text-align: center;
}
.product-list-tab>.active{
    border: none!important;
    background: #fff;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
    color: red !important;
    border-radius: 50px;
}
.setting-payment>thead>tr{
    height: 50px;
    background-color: #F8F6F5;
}
.setting-payment>thead>tr>th:first-child{
    padding-left: 20px;
}
.setting-payment-title{
    padding: 20px;
}
.setting-payment-title>img{
    margin-right: 20px;
}
.setting-payment-btn{
    width: 190px;
    height: 40px;
    background: none;
    border: 1px solid red;
    color: red;
    font-size: 16px;
}
.setting-payment-btn:hover{
    background: red;
    border: none;
    color: white;
}
.setting-payment>tbody>tr{
    height: 50px;
}
.setting-payment>tbody>tr>td{
   vertical-align: middle;
}
.setting-payment>tbody>tr>td:first-child{
    padding-left: 20px;
 }
.banner-option{
    padding: 20px;
}
.banner-option-image{
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
}
.banner-option-image>img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.banner-option-upload-btn{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-option-mobile{
    width: 200px;
    height: 200px;
    position: absolute;
    right: -1px;
    bottom: -1px;
    background: #bbb3b4a3;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-option-mobile>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}
/* New Product */

.modal-footer-btn {
    padding: 6px 8px;
    margin: 0;
    width: 100%;
    background: #e4e6eb !important;
    text-decoration: none !important;
}

.modal-footer-btn-fill {
    color: white;
    background: #0d4ef1 !important;
    text-decoration: none !important;
}

.modal-footer-btn-fill>span {
    color: white;
}

.modal-footer-btn-fill>span :hover {
    color: white;
    text-decoration: none !important;
}

.modal-Stepper {
    display: flex;
    align-items: stretch;
}

.disabled-link {
    pointer-events: none;
}

.modal-Stepper>div {
    height: 10px;
    background-color: #e4e6eb;
    margin-top: 4px;
    border-radius: 5px;
    margin: 5px;
    flex-grow: 1;
}

.modal-Stepper>div:first-child {
    margin-left: 0px;
}

.modal-Stepper>div:last-child {
    margin-right: 0px;
}

.modal-Stepper>div.fill {
    background: #2097e0 !important;
}


/* Product Detail Section */

.product-detail-section {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f3f3f3;
}

.product-detail-card {
    overflow: hidden;
    box-shadow: 0 0 6px 3px #99999940;
    border: 0;
    transition: all 0.5s ease;
}

.product-detail-imgBox {
    padding: 10px;
}

.product-detail-imgBox2 {
    display: block;
    height: 200px;
    width: 356px;
    backface-visibility: hidden;
}

.product-detail-imgThumb {
    padding: 10px;
}

.product-detail-imgThumb>img {
    display: inline-block;
    height: 50px;
    width: 50px;
    backface-visibility: hidden;
    border: 1px solid;
    margin: 5px;
}

.product-detail-header {
    padding: 10px;
}

.product-detail-title {
    font-size: 20px;
}

.product-detail-rating {
    color: #999;
    font-size: 10px;
}

.product-detail-stock {
    float: right;
    position: relative;
    top: 8px;
    font-size: 10px;
    color: #ff0102;
    border-color: #ff0102;
    font-weight: bold;
}

.product-detail-stock:hover {
    color: #fff;
    background-color: #ff0102;
    border-color: #ff0102;
}

.product-detail-stock:focus {
    box-shadow: none;
    color: #fff;
    background-color: #ff0102;
    border-color: #ff0102;
}

.product-detail-price {
    padding: 10px;
    padding-bottom: 10px;
}

.product-detail-price>span {
    text-decoration-line: line-through;
    font-size: 15px;
    padding-left: 10px;
}

.product-detail-size {
    padding: 10px;
    padding-bottom: 10px;
}

.product-detail-age {
    padding: 10px;
    /* border-bottom: 1px solid rgb(248, 54, 54); */
    padding-bottom: 10px;
}

.product-detail-color {
    padding: 10px;
    padding-bottom: 10px;
}

.product-detail-size-title {
    width: 200px !important;
}

.product-detail-sizeBtn {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    font-size: 10px;
    display: inline-block;
    padding: 0;
}

.product-detail-ageBtn {
    margin-right: 10px;
    height: 30px;
    width: 70px;
    font-size: 10px;
    display: inline-block;
    padding: 0;
}

.product-detail-colorBtn {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    padding: 0;
}

.product-detail-qty {
    padding: 10px;
}

.product-detail-total-price {
    padding: 10px;
}

.product-detail-optionBtn {
    margin: 10px;
    float: left;
    position: relative;
    top: 8px;
    font-size: 15px;
    color: #ff0102;
    border-color: #ff0102;
    font-weight: bold;
}

.product-detail-optionBtn:hover {
    color: #fff;
    background-color: #ff0102;
    border-color: #ff0102;
}

.product-detail-shipping {
    padding: 10px;
}

.product-detail-social-link {
    margin-top: 30px;
    text-align: left;
    padding: 10px;
}

.product-detail-social-link .link {
    margin-right: 20px;
    cursor: pointer;
}
.product-detail-description{
    width: 100%;
}

.product-detail-description>figure>img{
    width: 100%;
    object-fit: contain;
}


.product-detail-social-link{
    width: 100%!important;
}
.product-detail-social-link>ul{
    padding-left: 0!important;
}
.product-detail-social-link{
    top: 20px;
    text-align: left!important;
}
.product-detail-social-link>ul>li {
    list-style: none;
    display: inline-block;
    margin-right: 10px;
}
.detail-share-fb {
    width: 33px !important;
    height: 33px !important;
    background-color: #e7e7e7 !important;
    background-image: url("../images/fbDetailColor.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.detail-share-fb:hover {
    background-color: #1877f2 !important;
    background-image: url("../images/fbDetail.svg");
}

.detail-share-tw {
    width: 33px !important;
    height: 33px !important;
    background-color: #e7e7e7 !important;
    background-image: url("../images/twDetail.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.detail-share-tw:hover {
    background-color: #1d9bf0 !important;
    background-image: url("../images/twDetailW.svg");
}

.detail-share-pin {
    width: 33px !important;
    height: 33px !important;
    background-color: #e7e7e7 !important;
    background-image: url("../images/pinDetail.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.detail-share-pin:hover {
    background-color: #B7081A !important;
    background-image: url("../images/pinDetailW.svg");
}

.detail-share-ins {
    width: 33px !important;
    height: 33px !important;
    background-color: #e7e7e7 !important;
    background-image: url("../images/instaDetail.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.detail-share-ins:hover {
    background-color: #E23583 !important;
    background-image: url("../images/instaDetailW.svg");
}


.product-property-modal{
    display: flex;
    align-items: center;
    width: 100%;
}
.product-property-switch{
   height: 40px;
}
.product-property-title{
    height: 40px;
    padding-top: 10px;

 }
.detail-update-btn {
    font-size: 1.25rem !important;
    margin-bottom: 8px;
    margin-left: 10px;
    color: tomato;
}
.payment-curreny-btn{
    border: none;
    padding: 5px 15px 5px 15px;
    border-radius: 50px;
    margin-right: 10px;
}
.payment-curreny-btn>svg{
    margin-left: 10px;
    color: red;
}
.wrapper {
    width: 100%;
    margin-bottom: 10px;
}

.gallery {
    margin-top: 5px;
}

.gallery ul {
    list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0;
}

.gallery ul li {
    width: 50px;
    height: 50px;
    margin: 5px;
    padding: 5px;
    z-index: 0;
    display: inline-block;
    position: relative;
}

.gallery ul li:hover {
    z-index: 5;
}


/* Image is position nicely under li */

.gallery ul li img {
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #dddddd;
    padding: 3px;
    cursor: pointer;
    background: #f0f0f0;
    display: inline-block;
    height: 50px;
    width: 50px;
    backface-visibility: hidden;
}

.gallery ul li img:hover {
    width: 100px;
    height: 100px;
    margin-top: -60px;
    margin-left: -44px;
    top: 65%;
    left: 65%;
}


/*Order Detail Section*/

.order-detail-section-order-no {
    float: right;
    padding: 0 10px 10px 10px;
}

.order-detail-section-order-no>button {
    color: #229a16;
    background-color: rgba(84, 214, 44, 0.16);
    float: right;
    text-align: center;
    border-radius: 20px;
    font-weight: bold;
    border: none;
    width: 100%;
    height: 33px;
}

.order-detail-section-order-no>h6 {
    font-family: "Be Vietnam", sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: rgb(145, 158, 171);
    float: right;
    padding-top: 10px;
}

.order-detail-section-paid {
    float: right;
    padding: 0 10px 10px 10px;
    width: 135px;
}

.order-detail-section-paid>button {
    color: #229a16;
    background-color: rgba(84, 214, 44, 0.16);
    float: right;
    text-align: center;
    border-radius: 20px;
    font-weight: bold;
    border: none;
    width: 100%;
    height: 33px;
}

.order-detail-section-paid>h6 {
    font-family: "Be Vietnam", sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: rgb(145, 158, 171);
    float: right;
    padding-top: 10px;
}
.order-detail-section-title {
    padding: 10px;
}

.order-detail-section-title>h3 {
    margin: 0px 0px 16px;
    font-family: "Be Vietnam", sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: rgb(145, 158, 171);
}

.order-detail-section-title>p {
    line-height: 1.5;
    padding-left: 10px;
}

.order-detail-section-print-btn {
    border: none;
    padding: 10px;
}

.order-detail-section-print-btn>a>h6 {
    float: right;
    background-color: #f503032e;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-size: 12px;
    margin-left: 10px;
}

.invoice-head {
    margin: auto;
    text-align: center;
    margin-top: 10px;
}

.invoice-head>h3 {
    padding-top: 10px;
}

.invoice-print-section {
    padding: 10px;
}

.invoice-print-btn {
    color: #fe4217;
    background-color: rgba(214, 76, 44, 0.16);
    cursor: pointer;
    width: 70px;
    text-align: center;
    border-radius: 20px;
    font-weight: bold;
    padding: 5px 15px 5px 15px;
}
.invoice-watermark{
    display: none;
}
.order-customer-copy{
    display: none;
}
.order-copy{
    display: none;
}

@media print {
    .invoice-print-section {
        display: none;
    }
    .page-common-sub-title{
        display: none!important;
    }
    .invoice-watermark{
        width: 100vw;
        height: 200px;
        background-image: url(../images/watermark.svg);
        background-repeat: no-repeat;
        background-position: top;
        position: absolute;
        margin-left: 100px;
        margin-top: 75px;
        z-index: 99;
        opacity: .05;
        display: block;
    }
    .order-customer-copy{
        display: block;
        page-break-before: always;
    }
    .order-copy{
        display: block;
        float: right;
    }
    
}

.color-item {
    display: inline-block;
    margin: 5px;
    border: 1px solid #ddd;
    padding: 3px;
    border-radius: 5px;
}

.color-item.checked {
    border: 1px solid red;
}

.color-item>button {
    width: 28px;
    height: 28px;
    border: 0px solid;
    border-radius: 3px;
    display: block;
}


/*SHop Section*/

.feedback-progress-bar {
    display: flex;
}

.feedback-progress-bar>p {
    padding-left: 10px;
    padding-right: 10px;
}

.squire-image {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 9px -5px #000;
}

.squire-image::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.squire-image>div {
    position: absolute;
    width: 100%;
    height: 100%;
}

.squire-image>div img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
}

.btn-more {
    display: inline-block;
    background: #8bc34a;
    padding: 1px 9px;
    border-radius: 10px;
    color: #fff;
    font-size: 80%;
}

.dropdown-menu a {
    display: block;
}


/*Category Section*/

.category-list {
    padding: 20px;
}

.mob-product-prev-title {
    margin-top: -80px;
    padding-top: 23px;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    color: #fff;
    background: linear-gradient( 0deg, rgb(0 0 0 / 73%) 0%, rgb(0 0 0 / 54%) 74%, rgba(0, 0, 0, 0) 99%);
}
/*Setting Section*/
.text-field-margin{
    margin-top: 10px;
}

.invoice-print-btn {
    color: #fe4217;
    background-color: rgba(214, 76, 44, 0.16);
    cursor: pointer;
    width: 70px;
    text-align: center;
    border-radius: 20px;
    font-weight: bold;
    padding: 5px 15px 5px 15px;
    border: none;
    margin-left: 10px;
}
.stock-modal-body{
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    margin-bottom: 10px;
}
.stock-modal-body-info{
    width: 75%;
}
.stock-modal-body-stock{
    width: 25%;
}
.stock-modal-body-varient>ul{
    list-style: none;
    padding-left: 0;
}
.stock-modal-body-varient>ul>li{
    display: inline-flex;
    margin-right: 25px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #606780;
    
}
.stock-modal-body-varient>ul>li>button{
    height: 20px;
    width: 20px;
    background-color: red;
    border: none;
    border-radius: 50px;
    margin-left: 11px;
}
.stock-modal-body-varient>ul>li>span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}
.stock-modal-input{
    display: flex;
    flex-wrap: wrap;
}
.stock-modal-input-form-group{
    display: flex;
    width: 100%;
}
.stock-modal-input-form-group>.form-check{
    width: 50%;
}
.stock-modal-input-form-group>.form-check>label{
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #1C2649;
    opacity: 0.7;
}
.stock-modal-input-qty{
    width: 90%;
    display: flex;
    align-items: center;
}
.stock-modal-input-qty>label{
    width: 50%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #1C2649;
    opacity: 0.7;
}
.stock-modal-body-stock{
    width: 116px;
    height: 116px;
    background-color: #FFF2F2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.stock-modal-body-stock>p{
    width: 100%;
    text-align: center;
    margin-bottom: -50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #FE0002;
    opacity: 0.7;
}
.stock-modal-body-stock>h5{
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #FE0002;
}
.stock-modal-btn{
    width: 100%;
    border: none;
    background-color: red;
    color: white;
    height: 45px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}
.order-section-no-order-title {
    margin-bottom: 50px;
}
.order-section-no-order-title>h5 {
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: #00071b;
    opacity: 0.7;
}
.order-section-no-order-title>h6 {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #00071b;
    opacity: 0.5;
}
.order-section-no-order-img {
    margin: auto;
    width: 500px;
}
.order-section-no-order-img>img {
    margin: auto;
}
.shop-profile-table{
    width: 100%;
    margin-top: 20px;
}
.shop-profile-table>tbody>tr{
    height: 50px;
}
.shop-profile-table>tbody>tr>td:first-child{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #00071B;
    opacity: 0.5;
    width: 150px;
}
.shop-profile-table>tbody>tr>td:last-child{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #00071B;
}
@media print {
    @page {
      margin-bottom: 20px;
    }
    header{
        display: none!important;
    }
    .page-common-title{
        display: none;
    }
    nav{
        display: none;
    }
    .invoice-print-btn{
        display: none;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .col-print-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-print-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .order-detail-section-paid{
        display: none;
    }
  }
  