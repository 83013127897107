.store-area {
    display: flex;
    flex-wrap: wrap;
}

.prev-store {
    min-height: 300px;
    border-radius: 16px;
    background: #fff;
    padding: 16px;
    color: #000;
    box-shadow: 0 0 20px -10px #999;
}
.link{
    color: black;
    text-decoration: none;
}
.link:hover{
    color: black;
    text-decoration: none;
}